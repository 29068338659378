import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    groupList: { recordsTotal: 0 },
    groupData: {},
    group: {},
    page: 1,
    limit: 10,
}

export const GroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GRP_LIST_SUCCESS:
            return {
                ...state,
                groupList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GRP_DETAILS_SUCCESS:
            return {
                ...state,
                groupData: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GRP_DATA_SUCCESS:
            return {
                ...state,
                group: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GRP_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.GRP_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.GRP_DETAILS_ERROR:
        case ACTION_TYPES.GRP_LIST_ERROR:
        case ACTION_TYPES.GRP_DATA_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        default:
            return state
    }
}

