import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    expenseList: { recordsTotal: 0 },
    expenseData: {},
    page: 1,
    limit: 10,
}

export const ExpenseReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.EXPENSE_LIST_SUCCESS:
            return {
                ...state,
                expenseList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.EXPENSE_DETAILS_SUCCESS:
            return {
                ...state,
                expenseData: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.EXPENSE_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.EXPENSE_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.EXPENSE_LIST_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.EXPENSE_DETAILS_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }
}

