import { changeState } from "./common/changeStateReducer"
import { loginReducer } from "./Login/Reducer"
import { adminReducer } from "./adminProfile/Reducer"
import { AgentReducer } from "./CMS/Reducer"
import { UserReducer } from "./User/Reducer"
import { GroupReducer } from "./Group/Reducer"
import { ExpenseReducer } from "./Expenses/Reducer"
import { CategoryReducer } from "./Category/Reducer"
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { combineReducers } from "redux"
export const rootReducer = combineReducers({
    changeState: changeState,
    toastr: toastrReducer,
    form: formReducer,
    login: loginReducer,
    admin: adminReducer,
    cms: AgentReducer,
    user: UserReducer,
    category: CategoryReducer,
    group: GroupReducer,
    expense: ExpenseReducer
});