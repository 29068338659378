//FETCH_LIST Actions
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_ERROR = "FETCH_LIST_ERROR";
//PAGE_CHANGE Actions
export const USER_PAGE_CHANGE = "USER_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const USER_LIMIT_CHANGE = "USER_LIMIT_CHANGE"
//DEL_USER Actions
export const DEL_USER_SUCCESS = "DEL_USER_SUCCESS";
export const DEL_USER_ERROR = "DEL_USER_ERROR";
//USER_ACTIVE_STATUS Actions
export const USER_ACTIVE_STATUS_SUCCESS = "USER_ACTIVE_STATUS_SUCCESS";
export const USER_ACTIVE_STATUS_ERROR = "USER_ACTIVE_STATUS_ERROR";
//FETCH_USER Actions
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";
//EDIT_USER Actions
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";
//add user ACTIONS
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";