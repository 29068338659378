//FETCH_LIST Actions
export const GRP_LIST_SUCCESS = "GRP_LIST_SUCCESS";
export const GRP_LIST_ERROR = "GRP_LIST_ERROR";
//PAGE_CHANGE Actions
export const GRP_PAGE_CHANGE = "GRP_PAGE_CHANGE"
//LIMIT_CHANGE Actions
export const GRP_LIMIT_CHANGE = "GRP_LIMIT_CHANGE"
//group details
export const GRP_DETAILS_SUCCESS = "GRP_DETAILS_SUCCESS";
export const GRP_DETAILS_ERROR = "GRP_DETAILS_ERROR";
//group data
export const GRP_DATA_SUCCESS = "GRP_DATA_SUCCESS";
export const GRP_DATA_ERROR = "GRP_DATA_ERROR";